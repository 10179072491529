<template>
  <div class="import-container">
    <import-template title="导入账单" :uploadUrl="uploadUrl" :downloadUrl="downloadUrl">
      <div slot="remark">
        <div>*模板中红色字体部分为必填项，蓝色字体部分为当缴费状态为已缴费时，成为必填项，灰色部分为非必填项。</div>
        <div>最大支持10MB的excel文件，超过请拆分导入</div>
      </div>
    </import-template>
  </div>
</template>
<script>
import { ImportTemplate } from 'components/bussiness/index'
import { uploadUrl } from './api'
export default {
  components: {
    ImportTemplate
  },
  data () {
    return {
      uploadUrl,
      downloadUrl: '/static/excel/物业缴费导入模板.xlsx'
    }
  },
  created () {
    this.$setBreadcrumb('导入')
  }
}
</script>
