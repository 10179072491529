var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "import-container" },
    [
      _c(
        "import-template",
        {
          attrs: {
            title: "导入账单",
            uploadUrl: _vm.uploadUrl,
            downloadUrl: _vm.downloadUrl,
          },
        },
        [
          _c("div", { attrs: { slot: "remark" }, slot: "remark" }, [
            _c("div", [
              _vm._v(
                "*模板中红色字体部分为必填项，蓝色字体部分为当缴费状态为已缴费时，成为必填项，灰色部分为非必填项。"
              ),
            ]),
            _c("div", [_vm._v("最大支持10MB的excel文件，超过请拆分导入")]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }